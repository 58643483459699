<template>
  <auth>
    <div class="right-content" id="main-content">
      <div class="login-header">
        <div class="brand">
          <span class="logo"></span>
          <div class="brand-name"><span>Co-op</span><span>Bid</span></div>
        </div>
        <h4 class="register-header">Set Password</h4>
      </div>
      <div v-if="is_loaded">
        <div v-html="$error.handle(error)" />
        <form v-if="is_valid" @submit.prevent="submit">
          <div class="form-group">
            <label>Current Password</label>
            <input
              v-model="password.current_password"
              class="form-control"
              type="password"
            />
          </div>
          <div class="form-group">
            <label>New Password</label>
            <input
              v-model="password.new_password"
              class="form-control"
              type="password"
            />
          </div>
          <div class="form-group">
            <label>Confirm New Password</label>
            <input
              v-model="password.new_password_confirmation"
              class="form-control"
              type="password"
            />
          </div>
          <div class="form-group">
            <button class="btn btn-block btn-lg btn-primary">
              Change Password
            </button>
          </div>
          <br />
        </form>
        <div v-else class="alert alert-danger">
          <p>Your account could not be found. Plese contact support.</p>
        </div>
      </div>
      <loading v-else />
    </div>
  </auth>
</template>

<script>
import Auth from "@/components/Auth.vue";

export default {
  components: {
    Auth,
  },
  data() {
    return {
      app: process.env.VUE_APP_NAME,
      password: {
        current_password: "",
        new_password: "",
        new_password_confirmation: "",
      },
      is_valid: false,
      is_loaded: false,
      error: "",
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.is_valid = true;
      this.is_loaded = true;
    },
    submit() {
      this.is_loaded = false;
      this.$axios
        .post("/api/v1/update-password", this.password)
        .then(() => {
          this.$store.dispatch("initialize").then(() => {
            this.is_loaded = true;
            this.$router.replace({ name: "dashboard" });
          });
        })
        .catch((error) => {
          if (error.response) {
            this.error = error.response;
          }
          this.is_loaded = true;
        });
    },
  },
};
</script>
<style lang="scss">
.landing__width {
  margin: auto;
  max-width: 70%;
}
</style>
