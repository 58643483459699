<template>
  <div class="login login-with-news-feed">
    <!-- begin news-feed -->
    <div class="news-feed">
      <div class="news-image" :style="cssProps"></div>
    </div>
    <!-- end news-feed -->
    <!-- begin right-content -->
    <slot class="right-content"/>
    <!-- end right-container -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      cssProps: {
        backgroundImage: `url(${require('@/assets/img/login-bg.jpg')})`
      },
      app: process.env.VUE_APP_NAME
    }
  },
}
</script>
